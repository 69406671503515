import React from 'react'
import loadable from '@loadable/component'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import Hero from '../sections/common/Hero'
import { Section } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LsCTA from '../components/LsCTA'
import GetStarted from '../sections/landing1/GetStarted'
import setLanguage from '../helpers/languageConfig'
import coinsOffered from '../assets/image/svg/coinsOffered.svg'

const CurrenciesTable = loadable(() =>
  import('../components/Table/CurrenciesTable')
)
const ExchCurrsTable = loadable(() =>
  import('../components/Table/ExchCurrsTable')
)

const Currencies = props => {
  const { t, ready } = useTranslation(['cryptocurrencies', 'tables'], { useSuspense: false })
  const lang = setLanguage();
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const cta = {
    title: ready && t('missingTitle'),
    content: ready &&  t('missingText'),
    btnText: ready &&  t('missingBtn'),
    btnLink: `/${lang}/support`,
    icon: false,
  }

  return ready && (
    <>
      <MetaData page="cryptocurrencies" />
      <PageWrapper headerDark footerDark>
        <Hero
          bg={userDarkMode ? 'black' : 'bg'}
          title={t('heroTitle')}
          img={true}
          imgSrc={coinsOffered}
          externalLink={true}
          externalUrl={process.env.LS_SIGNUP}
          externalText={t('signUp')}
          firstP={t('currenciesText')}
          >
          {t('currenciesIntro')}
        </Hero>
        <Section bg={userDarkMode ? 'black' : 'bg'} pt={[0, null, null, 0]} pb={[20, null, null, 20]}>
          <Container className="mb-5">
            <Row>
              <Col lg="12">
                <ExchCurrsTable exchName="Lightspeed Crypto" exchCode="LSCX:" />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg="12">
                <CurrenciesTable />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg={userDarkMode ? 'black' : 'bg'}  pt={[40, null, null, 40]} pb={[0, null, null, 0]}>
          <Container>
            <Row className="mt-5">
              <Col lg="12">
                <LsCTA userDarkMode={userDarkMode} cta={cta} />
                <GetStarted />
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default Currencies
